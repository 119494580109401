import React from 'react'
import { TabTitle } from './GeneralFunctions'

const Notfound = () => {
  TabTitle("404 Not Found")
  return (
    <div className="notfound-container">404 | Not found</div>
  )
}

export default Notfound