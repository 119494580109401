import React from 'react'
import { RWebShare } from "react-web-share";
import IosShareIcon from '@mui/icons-material/IosShare';
const Esharing = ({brideGroomName}) => {
    const shareUrl = window.location.href;
    const instaUrl=window.location.href
  return (
    <RWebShare
    data={{
      text: ((brideGroomName)?`
      Thank You For making our Wedding so Special 🫶

      You can View all the Post-Wedding links here
      ${brideGroomName} - Wedding Links: ` 
       :`
       Thank You For making our Wedding so Special 🫶

      You can View all the Post-Wedding links here
       `),       
      url: shareUrl,
      title: "Wedding Links", 
    }}
  >
    <IosShareIcon  className='ended-share-icon'/>
  </RWebShare>
  )
}

export default Esharing