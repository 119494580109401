import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "../../components/helpers/Axios";

export const login=createAsyncThunk("user/login",async({values,navigate,setStateSnackbarContext},{rejectWithValue})=>{
    try {
       const res=await axios.post("/login",values) 
       setStateSnackbarContext(
        true,
        "logged in success",
        "success"
      )
       navigate("/")
      return res.data.user
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const singup=createAsyncThunk("user/signup",async({values,navigate,setStateSnackbarContext},{rejectWithValue})=>{
    try {
        const res=await axios.post("signup",values)
        setStateSnackbarContext(
            true,
            "signed in success",
            "success"
          )
           navigate("/")
          return res.data.user
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const loadUser=createAsyncThunk("user/load",async(arg,{rejectWithValue})=>{
try {
    const res=await axios.get("/me")
    return res.data.user  
} catch (error) {
    return rejectWithValue(error.response.data.message)
}
})

export const isSignedIn=createAsyncThunk("user/isSignedIn",async({id,setNotFound},{rejectWithValue})=>{
    try {
     const res=await axios.get(`/issignedin/${id}`)  
     if(res.data.user.hasSignedUp){
       setNotFound(true)
      }
    } catch (error) {
        if(error.response.data.message==="user not found"){
            setNotFound(true)
           }
        return rejectWithValue(error.response.data.message)
    }
})

export const forgotPassword=createAsyncThunk("password/forgot",async({values,setStateSnackbarContext,switchtologin},{rejectWithValue})=>{
    try {
         await axios.post("/password/forgot",values)
         setStateSnackbarContext(
            true,
            "check your email inbox or spam folder",
            "info"
         )
         switchtologin()

    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const newPassword=createAsyncThunk("password/reset",async({token,values,setStateSnackbarContext,navigate},{rejectWithValue})=>{
    try {
         await axios.put(`/password/reset/${token}`,values)
         setStateSnackbarContext(
            true,
            "password update success",
            "success"
         )
         setTimeout(() => {
            navigate("/login")
         }, 2000);
      
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const updateEmail=createAsyncThunk("email/update",async({values,setStateSnackbarContext},{rejectWithValue})=>{
    try {
          await axios.post("/email/update",values)
          setStateSnackbarContext(
            true,
            "check your email inbox or spam folder",
            "info"
         )
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})


export const changePass=createAsyncThunk("password/change",async({values,setStateSnackbarContext,setValues},{rejectWithValue})=>{
    try {
            await axios.put("/password/update",values)
            setStateSnackbarContext(
            true,
            "password update success",
            "success"
         )
         setValues({
            oldPassword: "",
            password: "",
            confirmPassword: ""
          });
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const logout=createAsyncThunk("user/logout",async({navigate,setStateSnackbarContext,end},{rejectWithValue})=>{
    try {
        await axios.get("/logout")
        if(end){
            setStateSnackbarContext(
                true,
                "Invite ended success please login again",
                "success"
            )
        }else{
        setStateSnackbarContext(
            true,
            "logged out success",
            "success"
        )
        }
        navigate("/login")
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
    })

    export const endInvite=createAsyncThunk("invite/end",async(arg,{rejectWithValue})=>{
        try {
            await axios.get("/invite/end")
        } catch (error) {

            return rejectWithValue(error.response.data.message)
        }
        })

     export const findEnded=createAsyncThunk("end/find",async({id,setHasEnded,setLoading,setNotFound,setSignedIn},{rejectWithValue})=>{
     
        try {
            const res=await axios.get(`/hasended/${id}`)
            if(res.data.hasEnded===true){
                setHasEnded(true)
            }
            if(res.data.hasSignedUp===false){
                setSignedIn(true)
            }
            setLoading(false)
        } catch (error) {

            if(error.response.data.message==="invalid user"){
                setNotFound(true)
            }
            return rejectWithValue(error.response.data.message)
        }
     }) 


const authSlice=createSlice({
    name:"user",
    initialState:{
      user:null,
      loading:false,
      isAuthenticated:false,
      error:""
    },
    reducers:{
     clearError:(state)=>{
        state.error=null;
     },
     clearUser:(state)=>{
        state.isAuthenticated=false;
     }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(login.pending,(state)=>{
            state.loading=true
        })
        .addCase(login.fulfilled,(state,action)=>{
            state.loading=false;
            state.user=action.payload;
            state.isAuthenticated=true;
        })
        .addCase(login.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(singup.pending,(state)=>{
            state.loading=true
        })
        .addCase(singup.fulfilled,(state,action)=>{
            state.loading=false;
            state.user=action.payload;
            state.isAuthenticated=true;
        })
        .addCase(singup.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(loadUser.pending,(state)=>{
            state.loading=true
        })
        .addCase(loadUser.fulfilled,(state,action)=>{
            state.loading=false;
            state.user=action.payload;
             state.isAuthenticated=true;
        })
        .addCase(loadUser.rejected,(state,action)=>{
            state.loading=false;
            state.isAuthenticated=false;
            state.error=action.payload
        })

        .addCase(forgotPassword.pending,(state)=>{
            state.loading=true
        })
        .addCase(forgotPassword.fulfilled,(state,action)=>{
            state.loading=false;
        })
        .addCase(forgotPassword.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        
        .addCase(newPassword.pending,(state)=>{
            state.loading=true
        })
        .addCase(newPassword.fulfilled,(state,action)=>{
            state.loading=false;
        })
        .addCase(newPassword.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })


        .addCase(updateEmail.pending,(state)=>{
            state.loading=true
        })
        .addCase(updateEmail.fulfilled,(state,action)=>{
            state.loading=false;
        })
        .addCase(updateEmail.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(changePass.pending,(state)=>{
            state.loading=true
        })
        .addCase(changePass.fulfilled,(state,action)=>{
            state.loading=false;
        })
        .addCase(changePass.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(logout.pending,(state)=>{
            state.loading=true
        })
        .addCase(logout.fulfilled,(state,action)=>{
            state.loading=false;
            state.isAuthenticated=false
        })
        .addCase(logout.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(endInvite.pending,(state)=>{
            state.loading=true
        })
        .addCase(endInvite.fulfilled,(state,action)=>{
            state.loading=false;
            state.isAuthenticated=false
        })
        .addCase(endInvite.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })
    }
})

export const {clearError,clearUser}=authSlice.actions

export default authSlice.reducer;