import React from 'react'

const PreviewContainer = ({handleView,avatar}) => {
  return (
    <div className="avatar-preview-container">
    <i className="fas fa-times-circle cancel-icon" onClick={handleView} ></i>   
    <div className="row d-flex justify-content-center">
            <div className="col-lg-8 col-md-8  d-flex justify-content-center view-img-container">
            <img src={avatar} alt="avatar" />
            </div>
        </div>
    </div>
  )
}

export default PreviewContainer