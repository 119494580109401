import React, { useEffect,useState } from 'react'
import {Container,Row} from 'react-bootstrap'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {useDispatch,useSelector} from 'react-redux'
import { getSingleEvent, update,clearError } from '../../../redux/reducers/eventSlice';
import { useParams,useNavigate } from 'react-router-dom';
import inputErrors from '../../helpers/validation'
import dayjs from 'dayjs';
import {snackbarContext} from '../../helpers/Snakbar'
import MapInput from './MapInput';
import { clearUser } from '../../../redux/reducers/authSlice';
import Navbar from '../../helpers/Navbar';
import { TabTitle } from '../../helpers/GeneralFunctions';
import Loader from '../../helpers/Loader';
import Footer from '../../helpers/Footer';
const UpdateEvent = () => {
  TabTitle("Update Event")
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const {user}=useSelector((state)=>state.auth)
  const {id} =useParams()
   const dispatch=useDispatch()
   const navigate=useNavigate()
   const {eventDetail,loading,error:backendError}=useSelector((state)=>state.event)
   const [values,setValues]=useState({
    eventName:" ",
    eventDate:eventDetail.eventDate,
    eventTime:" ",
    eventTheme:" ",
    eventInfo:" ",
    eventAddress:" ",
    group:" "
   })
   const [isSubmitting, setSubmitting] = useState(false);
   const [errors, setErrors] = useState({});
   const [time,setTime]=useState(dayjs('2023-04-17T12:00'))

  const handleChange=(event)=>{
    setValues({
        ...values,
        [event.target.name]: event.target.value
      });    
}


  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const handleTimeChange=(newTime)=>{
    const hours = dayjs(newTime).format('hh:mm A');
    setValues(() => ({
      ...values,
      eventTime:hours
   }));

  }

  const getConvertedTime=()=>{
    return dayjs(eventDetail.eventTime,'hh:mm A')
  }

  const getConvertedDate=()=>{
    const date=dayjs(eventDetail.eventDate)
    const formattedDate=date.format("YYYY-MM-DD")
    return formattedDate
  }


  const updateEvent=async()=>{
    setSubmitting(false)
     dispatch(update({id,values,navigate,setStateSnackbarContext}))
  }

  useEffect(()=>{
    dispatch(getSingleEvent(id))
  },[id])
  useEffect(()=>{
    if(isSubmitting){
      const noErrors = Object.keys(errors).length === 0;   
      if(noErrors){
        updateEvent()
      }
    }
  },[isSubmitting,errors])

  useEffect(()=>{

    if (backendError === "session expired" || backendError==="pls login to acess the resource") {
      setStateSnackbarContext(true, "session expired please login again", "error");
      dispatch(clearError());
      dispatch(clearUser())
      navigate("/login")
    }else if(backendError==="Event not found"){
        navigate("/display/notfound")
    }else if (backendError) {
      setStateSnackbarContext(true, backendError, "error");
      dispatch(clearError());
    }
  },[backendError])

  useEffect(()=>{
   setValues({
    eventName:eventDetail.eventName,
    eventTheme:eventDetail.eventTheme,
    eventInfo:eventDetail.eventInfo,
    eventAddress:eventDetail.eventAddress,
    eventDate:getConvertedDate(),
    gLocation:eventDetail.gLocation,
    group:eventDetail.group
   })
   setTime(getConvertedTime())
  },[eventDetail])

  if(user && user.hasEnded){
    navigate("/display/notfound")
    }

  const handleSubmit=(e)=>{
    e.preventDefault()
    const validationErrors=inputErrors(values)
   
    setErrors(validationErrors)
   setSubmitting(true)
  }
  const groupMappings = {
    'a': 'g1160a',
    'b': 'g2330b',
    'c': 'g3140c',
    'd': 'g5210d'
  };
  const generateGroupOptions = (numGroups) => {
    const options = [];
    for (let i = 0; i < numGroups; i++) {
      const groupLetter = String.fromCharCode(65 + i).toLowerCase();
      const encodedValue = groupMappings[groupLetter];
      options.push(
        <option key={i} value={encodedValue}>
          {`Group ${groupLetter.toUpperCase()}`}
        </option>
      );
    }
    return options;
  };
  return (
    <>
    <Navbar/>
    {loading?<Loader/>:<Container >
    <Row  className='justify-content-center px-2'>
    <div className="col-lg-8 col-md-11 event-container">
    <h3 className='event-form-heading'>Edit Event</h3>
    
    <form onSubmit={handleSubmit} className='event-form'>
    <div className={errors.eventName?"input-div-error":"input-div"}  >
    <input type="text"
     name="eventName" 
     value={values.eventName}
     onChange={handleChange}
     placeholder=' ' 
     className='input-field' />
    <label>Event name <span style={{color:"red"}}> * </span>  <span className='label-hide'>[ e.g. Reception ]  </span></label>
    </div>
    <div className='mb-3'>  {errors.eventName && (<small className="error-msg">{errors.eventName}</small>)} </div>
 
     <div className='row align-items-center'>
     
     <div className="col-sm-6">
      <input
        type="date"
        id="datePicker"
        name='eventDate'
        value={values.eventDate}
        onChange={handleChange}
        min={getCurrentDate()} // Set the min attribute to the current date
         required
        className='date-input'
      />
      </div>
     <div className='col-sm-6'>
    <LocalizationProvider dateAdapter={AdapterDayjs}  >
      <DemoContainer components={['TimePicker']}  className="time-container">
        <TimePicker
         label="Event time" 
         className='time-input' 
         name="eventTime" 
         value={time} 
         onChange={handleTimeChange}
         />
      </DemoContainer>
    </LocalizationProvider> 
    <div className='mb-3'>  {errors.eventTime && (<small className="error-msg">{errors.eventTime}</small>)} </div>
    </div>
   
    </div>
     <div className={errors.eventTheme?"input-div-error":"input-div"} >
     <input type="text" 
      placeholder=' ' 
      name="eventTheme" 
      value={values.eventTheme}
      onChange={handleChange}
      className='input-field' />
     <label>Theme   <span className='label-hide'>[ e.g. Brown  ]</span></label>     
     </div>
     <div className='mb-3'>  {errors.eventTheme && (<small className="error-msg">{errors.eventTheme}</small>)} </div>
     <div  className={errors.eventAddress?"input-div-error":"input-div"}>
     <input type="adress" 
     className='input-field' 
     name="eventAddress" 
     value={values.eventAddress}
     onChange={handleChange}
       placeholder=' ' />
     <label>Address  <span className='label-hide'>[ e.g. Sagun Palace ]</span></label>     
     </div>
     <div className='mb-3'>  {errors.eventAddress && (<small className="error-msg">{errors.eventAddress}</small>)} </div>
     <textarea
      name="eventInfo" 
      className={errors.eventInfo?"textarea-error":"info-textarea"}
      value={values.eventInfo}
       cols="30" 
       rows="3" 
       onChange={handleChange}
       placeholder='Addtional Information'></textarea>
       <div className='mb-3'>  {errors.eventInfo && (<small className="error-msg">{errors.eventInfo}</small>)} </div>
       <MapInput values={values} setValues={setValues} update="true" />
        {user?.groupSettings.enabled && (
                <div className={errors.group ? 'input-div-error' : 'input-div'}>
                <small style={{ display: "inline-block", marginBottom: "10px",color:"blue" }}><b>Update Group </b> </small>
                  <select
                    name="group"
                    value={values.group}
                    onChange={handleChange}
                    className="input-field"
                  >
                   {generateGroupOptions(user.groupSettings.numberOfGroups)}
                  </select>
                 
                </div>
              )}
     <button className='event-submit-button' type='submit'>Submit</button>
     </form>
     </div>
     </Row>
     </Container>}
     <Footer userCode={user&&user.userCode}/>
    </>
  )
}

export default UpdateEvent