import React,{useState,useEffect} from 'react'
import BasicDetailsForm from './BasicDetailsForm'
import BrideGroomForm from './BrideGroomForm';
import ExtraDetails from './ExtraDetails';
import Navbar from '../../helpers/Navbar';
import { useSelector,useDispatch } from 'react-redux';
import {snackbarContext} from '../../helpers/Snakbar'
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../../redux/reducers/authSlice';
import { clearError } from '../../../redux/reducers/profileSlice';
import {TabTitle} from '../../helpers/GeneralFunctions'
import Footer from '../../helpers/Footer';
const FormContainer = () => {
  TabTitle("Wedding Details")
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const {error:backendError} = useSelector((state) => state.profile)
   const {user} = useSelector(state => state.auth);

  const [currentStep, setCurrentStep] = useState(1);
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(()=>{
    if (backendError === "session expired" || backendError==="pls login to acess the resource") {
        setStateSnackbarContext(true, "session expired please login again", "error");
        dispatch(clearError())
        dispatch(clearUser())
        navigate("/login")
    }else if (backendError) {
        setStateSnackbarContext(true, backendError, "error");
        dispatch(clearError());
      }
},[backendError])




if(user && user.hasEnded){
navigate("/display/notfound")
}




  return (
    <>
    <Navbar/>
      {currentStep === 1 &&
        <BasicDetailsForm  
        // weddingData={weddingData} 
        // setWeddingData={setWeddingData}
        onNext={handleNext}
         />
      }
      {currentStep===2 &&  
      <BrideGroomForm  
      //  weddingData={weddingData} 
      //  setWeddingData={setWeddingData}   
       onBack={handleBack}
       onNext={handleNext}
        />
      }

      {currentStep===3 &&  
      <ExtraDetails
      //  weddingData={weddingData} 
      //  setWeddingData={setWeddingData}   
       onBack={handleBack}
       onNext={handleNext}
        />
      }
      <Footer userCode={user&&user.userCode}/>
    </>
  
  )
}

export default FormContainer