import React,{useState} from 'react'

const CountdownTimer = ({targetDate}) => {
    const calculateTimeRemaining = () => {
        const now = new Date();
        const target = new Date(targetDate);
        const timeRemaining = target - now;

        if (timeRemaining <= 0) {
          return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    
        return { days, hours, minutes, seconds };
      };
      const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  return (
    <>
     <p>{timeRemaining.days} </p>
     </>
  )
}

export default CountdownTimer