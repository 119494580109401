import {createAsyncThunk,createSlice} from '@reduxjs/toolkit'
import axios from "../../components/helpers/Axios";
export const createEvent=createAsyncThunk("event/create",async({values,navigate,setStateSnackbarContext},{rejectWithValue})=>{
    try {
        const res=await axios.post("/event/new",values)
        setStateSnackbarContext(
                true,
                "event created success",
                "success"
              )
        navigate("/events")
         return res.data.event
    } catch (error) {
        
       return rejectWithValue(error.response.data.message) 
    }
})

export const getMyEvents=createAsyncThunk("my/events",async(data,{rejectWithValue})=>{
    try {
      const res=await axios.get("/events")
       return  res.data.events
    } catch (error) {
        
        return rejectWithValue(error.response.data.message) 
    }
})


export const getSingleEvent=createAsyncThunk("event/single",async(id,{rejectWithValue})=>{
    try {
        const res=await axios.get(`/event/${id}`)
         return res.data.event
    } catch (error) {
        return rejectWithValue(error.response.data.message) 
    }
})


export const update=createAsyncThunk("event/update",async({id,values,navigate,setStateSnackbarContext},{rejectWithValue})=>{
    try {
        const res=await axios.put(`/event/${id}`,values)
        setStateSnackbarContext(
            true,
            "event updated success",
            "success"
          )
        navigate("/events")
     return res.data.event
       
    } catch (error) {
        

        return rejectWithValue(error.response.data.message) 
    }
})


export const deleteEvent=createAsyncThunk("event/delete",async({id,setStateSnackbarContext},{rejectWithValue})=>{
    try {
        const res=await axios.delete(`/event/${id}`)
        setStateSnackbarContext(
            true,
            "event deleted success",
            "success"
          )
         return res.data.message
    } catch (error) {
        
        return rejectWithValue(error.response.data.message) 
    }
})

export const getEventsById=createAsyncThunk("events/id",async({id,group},{rejectWithValue})=>{
    try {
        const res=await axios.get(`/events/${id}`,{
            params: { group }
          })
        return res.data.events
    } catch (error) {
        
        return rejectWithValue(error.response.data.message) 
    }
})

export const reorderList=createAsyncThunk("events/reorder",async(events,{rejectWithValue})=>{
    try {
        const res=await axios.post(`/events/reorder`,events)
         return res.data.userEvent.events
    } catch (error) {
        
        return rejectWithValue(error.response.data.message) 
    }
})

const eventSlice=createSlice({
    name:"event",
    initialState:{
        loading:false,
        events:[],
        error:null,
        eventDetail:{}
    },
    reducers:{
        clearError:(state)=>{
            state.error=null;
         }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(createEvent.pending,(state)=>{
            state.loading=true
        })
        .addCase(createEvent.fulfilled,(state,action)=>{
            state.loading=false;
            state.events=[action.payload]
        })
        .addCase(createEvent.rejected,(state,action)=>{
            state.loading=false;
           state.error=action.payload
        })

        .addCase(getMyEvents.pending,(state)=>{
            state.loading=true
        })
        .addCase(getMyEvents.fulfilled,(state,action)=>{
                  state.loading=false;
                state.events=action.payload
        })
        .addCase(getMyEvents.rejected,(state,action)=>{
                 state.loading=false;
               state.error=action.payload
        })
        
        .addCase(getSingleEvent.pending,(state)=>{
            state.loading=true
        })
        .addCase(getSingleEvent.fulfilled,(state,action)=>{
             state.loading=false;
            state.eventDetail=action.payload
        })
        .addCase(getSingleEvent.rejected,(state,action)=>{
               state.loading=false;
            state.error=action.payload
        })


        .addCase(update.pending,(state)=>{
            state.loading=true
        })
        .addCase(update.fulfilled,(state,action)=>{
                  state.loading=false;
          state.eventDetail=action.payload
        })
        .addCase(update.rejected,(state,action)=>{
               state.loading=false;
            state.error=action.payload
        })

        .addCase(deleteEvent.pending,(state)=>{
            state.loading=true
        })
        .addCase(deleteEvent.fulfilled,(state,action)=>{
                  state.loading=false;
          state.eventDetail=action.payload
        })
        .addCase(deleteEvent.rejected,(state,action)=>{
               state.loading=false;
            state.error=action.payload
        })

        .addCase(getEventsById.pending,(state)=>{
            state.loading=true
        })
        .addCase(getEventsById.fulfilled,(state,action)=>{
            state.loading=false;
          state.events=action.payload
        })
        .addCase(getEventsById.rejected,(state,action)=>{
               state.loading=false;
            state.error=action.payload
        })

        .addCase(reorderList.pending,(state)=>{
            state.loading=true
        })
        .addCase(reorderList.fulfilled,(state,action)=>{
            state.loading=false;
          state.events=action.payload
        })
        .addCase(reorderList.rejected,(state,action)=>{
               state.loading=false;
            state.error=action.payload
        })
    }
})

export const {clearError}=eventSlice.actions

export default eventSlice.reducer;

