
import React from 'react'
import EditableLink from './EditableLink'
import './display.css'
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd'
import { reorderLink } from '../../../redux/reducers/endSlice'
import { useDispatch } from 'react-redux'

const DisplayLinks = ({links}) => {

  const dispatch=useDispatch()
  const onDragEnd=(result)=>{
    if(!result.destination) return;
   const newItems = Array.from(links);
   const [removed] = newItems.splice(result.source.index, 1);
  newItems.splice(result.destination.index, 0, removed)
     dispatch(reorderLink(newItems))
  }
 
  return (  
    <>
    <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable-1" >
    {(provided)=>(
      <div ref={provided.innerRef}  {...provided.droppableProps} >
      {links&&links.map((link,i)=>(
        <Draggable key={link._id} draggableId={"draggable-"+link._id} index={i} > 
        {((provided,snapshot)=>(
      <EditableLink link={link} key={i} provided={provided} snapshot={snapshot}/>
      ))}
       </Draggable>
    ))}
      </div>
    )}
    </Droppable>
    </DragDropContext>
   </>
   
   
  )
}

export default DisplayLinks