import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import EventPage from './components/wedding/Events/EventPage'
import CreateEvent from './components/wedding/Events/CreateEvent'
import UpdateEvent from './components/wedding/Events/UpdateEvent'
import Switching from './components/authentication/Switching';
import store from './redux/store'
import { loadUser } from './redux/reducers/authSlice';
import "bootstrap/dist/css/bootstrap.min.css"
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import FormContainer from './components/wedding/Forms/FormContainer'
import ProtectedRoute from './components/helpers/ProtectedRoute'
import Signup from './components/authentication/Signup'
import ResetPassword from './components/authentication/ResetPassword'
import Setting from './components/wedding/settings/Setting'
import Display from './components/helpers/Display'
import Landing from './components/wedding/Landing'
import EndHome from './components/wedding/EndInvite/EndHome'
import Notfound from './components/helpers/Notfound'
import CreateLink from './components/wedding/EndInvite/CreateLink'
import Appreance from './components/wedding/EndInvite/Appreance'
import FinalHome from './components/wedding/FinalOutput/FinalHome'
import { useLocation } from 'react-router-dom';
import Tac from './components/helpers/Tac'

const App = () => {
  const location = useLocation()
  const isAlphanumeric = /^[A-Za-z0-9]{9}$/.test(location.pathname.substr(1));
  const isExcludedPath = [
    '/signup/',
    '/password/reset/',
    '/display/',
    '/terms-and-condition'
  ].some(path => location.pathname.startsWith(path));

  useEffect(() => {
    if (!isAlphanumeric && !isExcludedPath) {
      store.dispatch(loadUser());
    }

  }, [location.pathname])

  return (
    <Routes>
      <Route path='/' element={<ProtectedRoute />} >
        <Route index element={<Landing />} />
      </Route>
      <Route path='/details' element={<FormContainer />}></Route>
      <Route path='/events' element={<EventPage />}></Route>
      <Route
        path="/event/new"
        element={<ProtectedRoute />} >
        <Route index element={<CreateEvent />} />
      </Route>
      <Route path="/event/:id" element={<UpdateEvent />} />
      <Route
        path="/settings"
        element={<ProtectedRoute />}>
        <Route index element={<Setting />} />
      </Route>
      <Route
        path="/endinvitation"
        element={<ProtectedRoute />}>
        <Route index element={<EndHome />} />
      </Route>
      <Route path='/links' element={<CreateLink />} />
      <Route path='/appreances' element={<Appreance />} />
      <Route path='/signup/:id' element={<Signup />} />
      <Route path='/login' element={<Switching />} />
      <Route path='/password/reset/:token' element={<ResetPassword />} />
      <Route path='/display/:screen' element={<Display />} />
      <Route path='/terms-and-condition' element={<Tac/>}  />
      <Route path='/:username' element={<FinalHome />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  )
}

export default App