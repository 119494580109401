import React from 'react'
import { TabTitle } from './GeneralFunctions'

const Tac = () => {
    TabTitle("Terms and conditions")
  return (
    <div className="container">
    <div className="col-lg-8 justify-content-center">
    <h3 className='ml-3 py-3'>Terms and Conditions</h3>
    <p> <b>Content</b> - Has the meaning given in clause 6(a). 
    <br />
<b>Data</b>  - Has the meaning given in clause 10(a). 
<br />
<b>Data Analytics </b> - Has the meaning given in clause 10(b). 
<br />
<b>End User</b>  - Has the meaning given in clause 12.
<br />
<b>Feedback</b>  - Has the meaning given in clause 11(b).
<br />
<b>Intellectual Property Rights</b>   - All industrial and intellectual property rights throughout the world, including all copyright and analogous rights, all rights in relation to inventions or discoveries (including patent rights), designs, registered and unregistered trade marks (including service marks), trade names, brand names, indications of source or appellations of origin, know-how, software, circuit layouts and all other rights throughout the world resulting from intellectual activity in the industrial, scientific or artistic fields.</p>

<br /><b>Jurisdiction Specific Terms</b>  - The terms in clause 18.
<br /> <b>Aamantran.link Content</b>   - Has the meaning given in clause 9(f). 
<br /> <b>Aamantran.link Service </b> - The service, delivered by us through the Website (or by any other means from time to time, including an application), that allows you to create a Page. 
<br /> <b> Page</b>  - A personalised and easily-customizable page that houses your Content, including all the important links you want to share with your audience.
<br /> <b>Privacy Policy</b>  - Our privacy policy, available here https://aamantran.co.in/privacy-policy/
<br />  <b>We/our/us - aamantran India.</b>
<b>Website - The website located at www.aamantran.link</b>
<p> <b> 1.2 Interpretation</b>
In these terms of service:
(a) headings in bold type are for convenience only and do not affect the interpretation of these terms of service;
(b) words of any gender include all genders;
(c) an expression referring to a person includes any company, partnership, joint venture, association, corporation or other body corporate and any governmental agency as well as an individual;
(d) a reference to any legislation, regulations, binding directions issued by government agencies, mandatory codes of conduct, or similar, includes all delegated instruments made under them and any amendments, consolidations, replacements or re-enactments of any of them;
(e) a reference to a day means the period of time commencing at midnight and ending 24 hours later;and
(f) specifying anything in these terms of service after the words “include” or “for example” or similar expressions does not limit what else is included.
</p>
<p> <b >2. Acceptance</b>
<br />(a) <b>These are binding terms</b> : By accessing or using the aamantran.link Service:  
(1) you agree to be bound by these terms of service. If you disagree with any part of these terms of service then you may not access the Service; and
(2) you confirm that you can form a binding contract with us, that you accept these terms of service and that you agree to comply with them.

<br />(b)   <b> Please comply with our Privacy Policy:</b> Your access to and use of the aamantran Service is also subject to our Privacy Policy. By using the aamantran Service, you consent to the terms of the Privacy Policy.
<br />
(c)  <b>Jurisdiction Specific Terms:</b>  If you access or use the aamantran Service from a jurisdiction for which there are Jurisdiction Specific Terms, you also hereby agree to the specific terms applicable to each relevant jurisdiction and in the event of a conflict between the provisions of these terms of service and the Jurisdiction Specific Terms, the Jurisdiction Specific Terms will prevail to the extent of the inconsistency.

(d) <b> You are the authorised representative of a business (if relevant): <br /></b>  If you are accessing the Service on behalf of a business or entity then you represent and warrant that you are an authorised representative of the business or the entity with the authority to bind the entity to these terms of service, and that you agree to these terms of service on the entity’s behalf.
<br />
(e) <b> Eligibility:</b>   You warrant that you are at least 16 years old and you are legally capable of entering into binding contracts. If you are under 16 years old, you must obtain the consent from your parent or guardian for you to use the aamantran Service and they agree to be bound by these terms of service on your behalf.<br />
<br /> 
3.  <b> Term</b> <br />

(a) This account is included and given as a complementary service to the customers of aamantran.co.in

<br />(d)  <b> Account deletion:</b> If you no longer want to use your account and would like your account deleted permanently, you can do this by contacting us through the help page. You should note though that if you do this, you will not be able to reactivate your account and will not be able to retrieve any of the Content or information you have added.

<br />(e)   <b>We can cancel or suspend your account:</b>  Please note that these terms of service allow us to suspend or cancel your account in certain circumstances.
<br />
<br /> 
4. <b> Changes to the Terms and the aamantran Service</b>
<br />

(a) We can change these terms of service: We may change or replace these terms of service at any time if the change is necessary to reflect changed or added functionality of the aamantran Service, for compliance with law, for reasonable commercial reasons, or otherwise to protect our legitimate interests.  
</p>
<p>
<br />
(b) We may notify you of certain changes to the terms of service: If a change to these terms of service may have a material negative effect on you, we will use our best efforts to advise you of the change in advance (for example, by providing a notification on the Website), and we will aim to do so least 1 month prior to any new terms taking effect. 
<br />
(c) You must check these terms of service for changes: It is your responsibility to check these terms of service from time to time for any changes. If you do not agree with any of the changes to these terms of service, it is your responsibility to stop using the aamantran Service and/or cancel in accordance with clause 3. 
<br />
(d) Your continued use deemed to be acceptance: Your continued use of the Service will be deemed as your acceptance of any changes to these terms of service, as described above.
<br />
(e) We can change the aamantran Service and functionality: You acknowledge that the aamantran Service is constantly evolving, and we may change parts of the aamantran Service and its functionality from time to time. 
<br />
<br />

5. <b> Accounts</b>
<br />
(a) You must create an account to use the aamantran Service: To access or use our aamantran Service, you must create an account with us. When you create this account you must provide accurate and up-to-date information. It is important that you maintain and update your details and any other information that you provide to us. 
<br />
(b) You must maintain your password: You agree not to disclose your password to any third party, and you are responsible for safeguarding the password that you use. You will be fully responsible for activities that relate to your account or your password. You must notify us immediately upon becoming aware of any breach of security or unauthorised use of your account.
<br />
(c) Your responsibilities regarding the use of your account: You must not use your user account or allow it to be used in a way which may (in our opinion) cause damage to or impair the aamantran Service or our reputation, or infringe or violate any third party rights, or violate any applicable laws or regulations.
<br />
<br />

<b> 6. Your Content</b>
<br />
(a) Your right to post Content: You may post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material to/on the Page that you create using the aamantran Service (Content). You are responsible for the Content, including its legality, reliability, and appropriateness.  Where any third party owns any of the Content, you are also responsible for ensuring that you have all rights (including any licences) needed to allow you to make available that Content on the Page and the aamantran Service.

<br />

(b) You grant us a license to use and display your Content: By posting Content to the aamantran Service, you grant us the right and license to use, publicly display and distribute such Content on and through the aamantran Service and you confirm that you have all third party rights and licences necessary to post that Content. You retain any and all of your rights to any Content you submit, post or display on or through the aamantran Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the aamantran Service, who may also use your Content subject to these terms of service.
<br />
(c) Your responsibilities in posting Content: You agree that: 

the Content is yours (you own it) or you have the right to use it and grant us the rights and licence as provided in these terms of service; and 

the posting of your Content on or through the aamantran Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person. Further, you agree that:

(A) the Content will not cause you or us to breach any law, regulation, rule, code or other legal obligation;

(B) the Content will not or could not be reasonably considered to be obscene, inappropriate, defamatory, disparaging, indecent, seditious, offensive, threatening, abusive, liable to incite racial hatred, discriminatory, blasphemous, in breach of confidence or in breach of privacy. In some circumstances, Content which in one country would not infringe the previous requirements could do so in another country (and we reserve the right to take whatever action we think necessary in such circumstances including removing Content or restricting access to the aamantran Service);

(C) the Content will not be misleading or deceptive, be intended or designed to misinform, would be likely to misinform a reasonable person, and that you will not represent as fact something which is false;

(D) the Content will not be unsolicited, undisclosed or constitute unauthorised advertising or endorsements of any product;

(E) the Content does not contain software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software, hardware or telecommunications equipment; and

(F) the Content does not and is unlikely to bring us or the aamantran Service into disrepute.

(d) Keeping records: You agree to keep all records necessary to establish that your Content does not violate any of the requirements of this clause 6 and make such records available upon our reasonable request.

(e) Our right to monitor and modify your Content: We are under no obligation to regularly monitor the accuracy or reliability of your Content incorporated into the aamantran Service. We reserve the right to modify or remove any Content at any time.

(f) You accept risk of public disclosure: You acknowledge and agree that all Content you provide on the aamantran Service will be publicly available information and you bear the risks involved with such public disclosures.

(g) We can use your Content to promote the aamantran Service: We may choose to feature your Page and Content (but not your registered trademarks or personal information, unless you agree in writing) on our Website or otherwise to promote the aamantran Service. You grant to us a royalty-free, worldwide, perpetual license to use such Content for such purposes. 
<br />
<br />
<b> 7. Acceptable use</b> <br />
Your access to and use of the aamantran Service is subject to these terms of service and all applicable laws and regulations. We are also committed to ensuring that the aamantran Service is safe for all users and does not provide a platform for inappropriate Content or user behaviour.  Where there is any genuine and reasonable allegation from a third party (including any law enforcement agency) that your conduct may have breached these terms of service, we reserve the right to cooperate with that third party.

With this in mind, you must not:

(a) access or use the aamantran Service if you are not fully able and legally competent to agree to these terms of service;

(b) make unauthorised copies, modify, adapt, translate, reverse engineer, disassemble, decompile, extract information from or create any derivative worksof the aamantran Service or any content included, including any files, tables or documentation (or any portion of these) or determine or attempt to determine any source code, algorithms, methods or techniques embodied by the aamantran Service or any derivative works of the aamantran Service (or authorise any other person to do so on your behalf);

(c) distribute, license, transfer, or sell, all or any part of any of the aamantran Service or any derivative works thereof, except that you may re-sell the aamantran Service to a limited class of persons if we have expressly given consent for such activity to you in writing (and, if we do give consent, we may also include conditions to such consent, which you must comply with);

(d) market, rent or lease the aamantran Service for a fee or charge, or use the aamantran Service to advertise or perform any commercial solicitation, except that you may market the aamantran Service to a limited class of persons if we have expressly given consent for such activity to you in writing;

(e) use the aamantran Service, without our express written consent, for any commercial or unauthorized purpose, including communicating or facilitating any commercial advertisement or solicitation or spamming;

(f) interfere with or attempt to interfere with the proper working of the aamantran Service, disrupt the Website or any networks connected to the aamantran Service, or bypass any measures we may use to prevent or restrict access to the aamantran Service;

(g) incorporate the aamantran Service or any portion of it into any other program or product, except as may be agreed separately with us in writing;

(h) use, or authorise others to use, automated scripts or other scraping tools to collect information from your Page, or the aamantran Service, or otherwise interact with the aamantran Service;

(i) impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with any person or entity, including giving the impression that any content you upload, post, transmit, distribute or otherwise make available comes from the aamantran Service;

(j) intimidate or harass another person, or promote violence or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age on your Page, your account, or on or through any links on your Page;

(k) include any sexually explicit material (including pictures and language) on your Page itself or your account itself;

(l) use or attempt to use another’s account, service or system without authorisation from us, or create a false identity on the aamantran Service;

(m) use the aamantran Service in a manner that may create a conflict of interest or undermine the purposes of the aamantran Service, such as trading reviews with other users or writing or soliciting fake reviews; or

(n) use the aamantran Service to upload, transmit, distribute, store, or otherwise make available in any way:

files that contain viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful; 
any information that in aamantranopinion constitutes unsolicited or unauthorised advertising, solicitations, promotional materials, “junk mail”, “spam”, “multi-level marketing”, “chain letters”, “pyramid schemes”, or any prohibited form of solicitation; 
any private information of any third party, including addresses, phone numbers, email addresses, number and feature in a personal identity document (e.g. National Insurance numbers, passport numbers) or credit card numbers, other than to us as contemplated in the Privacy Policy;

any material which does or may infringe any copyright, trade mark or other intellectual property or privacy rights of any other person;

any material which is defamatory of any person, or obscene, offensive, hateful or inflammatory;

any material that would constitute, encourage or provide instructions for a criminal offence, dangerous activities or self-harm;

any material that is deliberately designed to provoke or antagonise people, especially trolling and bullying, or is intended to harass, harm, hurt, scare, distress, embarrass or upset people;

any material that contains a threat of any kind, including threats of physical violence;

any material that is racist or discriminatory, including discrimination on the basis of someone’s race, religion, age, gender, disability or sexuality;

any answers, responses, comments, opinions, analysis or recommendations that you are not properly licensed or otherwise qualified to provide; or

material that, in our sole judgment, is objectionable or which restricts or inhibits any other person from using the aamantran Service, or which may expose us, the aamantran Service or our users to any harm or liability of any type.
<br />

<b> 8. Intellectual property</b>
<br />


(a) Our Intellectual Property Rights: All Intellectual Property Rights in the aamantran Service, the Website, and their content (excluding your Content, but including the aamantran Content), features and functionality are and will remain our (or our licensors’) exclusive property, and you will not challenge such ownership. 

(b) No transfer of our Intellectual Property Rights to you: The aamantran Service is protected by copyright, trade mark, and other laws of both Australia and foreign countries. Our trade marks and trade names and ‘get up’ may not be used in connection with any product or service without our prior written consent. Nothing in these terms of service constitutes a transfer of any Intellectual Property Rights from us to you.

(c) Your limited rights to use the aamantran Service: You are permitted to use the aamantran Service only as authorized by us (which includes use in accordance with these terms and conditions). As a user, you are granted a limited, non-exclusive, revocable, non-transferable right to use the aamantran Service to create, display, use, play, and upload Content subject to these terms of service.

(d) How the aamantran Service must not be used: You must not use the aamantran Service in connection with a product or service that is not affiliated with us or in any way brings us into disrepute.

(e) Responsibility for material posted: Any opinions, advice, statements, services, offers, or other information or content expressed or made available by any other users are those of the respective authors or distributors and not of us.

(f) Your responsibilities in using aamantran Content: If we provide you with any images, icons, video, graphics, or other content (aamantran Content) for you to use in connection with the aamantran Service, you must: 

(1) only use such aamantran Content on your Page and not anywhere else; 

(2) comply with any reasonable written guidelines or terms, which may be the guidelines or terms of a third party, in relation to the aamantran Content that we provide to you.   
<br />
<b>9. Privacy and data guardianship</b> 
<br />
(a) Our data, and guardianship of our data: All data (and any Intellectual Property Rights in data) that we or the aamantran Service create or generate based on your use (or End Users’, or other users’ use) of the aamantran Service or the Content (Data) will be owned by us and you will not dispute such ownership. 

(b) We may provide you with Data: We may provide Data or visualisations of Data to you as part of the aamantran Service (Data Analytics). We make no representations or warranties as to the accuracy or completeness of the Data Analytics, but we will try to make it as accurate and complete as we can.   

(c) Please refer to our Privacy Policy: Our Privacy Policy applies to the way in which we may collect, use, and disclose personal information about you.  
<br />
<b>10. Confidentiality</b> 
<br />
(a) You must protect confidential information provided to you: If we share information about the aamantran Service with you that is confidential, or that a reasonable person would consider is confidential, you must keep it confidential and use reasonable security measures to prevent unauthorised disclosure of or access to that information. 

(b) Our responsibilities regarding Feedback: If you choose to contribute to the aamantran Service by sending us any ideas for new products, services, features, modifications, enhancements, content, offerings, promotions, computer code, or any other materials (Feedback), then regardless of what your communication may say:

we have no obligation to review, consider, or implement your Feedback or to return to you all or part of any Feedback for any reason;

Feedback is provided on a non-confidential basis, and we are under no obligation to keep any Feedback you send confidential or to refrain from using or disclosing it any way; and

you irrevocably grant us a perpetual irrevocable transferable worldwide royalty free licence to reproduce, distribute, create derivative works of, modify, perform, communicate to the public, make available, display, and otherwise use and exploit the Feedback and derivatives thereof for any purpose and without restriction.
<br />
<b>11. Your responsibility for end users</b> 
<br />
Your Page may have its own visitors and customers (End Users). The way in which the End Users use and interact with your Page and your Content (as distinct from our obligations to you in these terms of service in respect of the aamantran Service itself) is solely your responsibility. This responsibility includes compliance with all laws and regulations in relation to End Users, and the supply of products and services (if any) to End Users. 
<br />
<b>12. Liability</b> 
<br />
(a) We are not liable for damages: You agree that we will not be liable for any damages suffered as a result of using the aamantran Service, or copying, distributing, or downloading Content from the aamantran Service.

(b) No liability for certain types of damage for breach of contract or tort: In no event will you or we be liable for any indirect, punitive, special, incidental or consequential damage (including loss of business, revenue, profits, use, privacy, data, goodwill or other economic advantage) however it arises, whether for breach of contract or in tort (including negligence), even if the relevant party has been previously advised of the possibility of such damage.

(c) You are responsible for your use of the aamantran Service: You have sole responsibility for adequate security protection and backup of data, Content, and/or equipment used in connection with your usage of the aamantran Service and will not make a claim against us for lost data, re-run time, inaccurate instruction, work delays or lost profits resulting from the use of the aamantran Service. You must not assign or otherwise dispose of your account to any other person.

(d) Indemnity: You will indemnify us against any loss suffered by us that arises out of a breach by you of these terms of service (including clause 7), or a third party claim made against us in relation to your Content.

(e) Limitation of our liability to you: In no event will our liability to you under or in connection with these terms of service or the aamantran Service exceed the greater of the fees actually paid by you to us at the time the liability purportedly arose, or [$100]. This limitation applies regardless of whether your claim against us is based on contract, negligence, other torts or otherwise.
<br />
<b> 13. Disclaimer </b>
<br />
(a) You use the aamantran Service at your own risk: Your use of the aamantran Service is at your sole risk. The aamantran Service is provided on an "AS IS" and "AS AVAILABLE" basis. The aamantran Service is provided without warranties of any kind, whether express or implied, including, but not limited to, up-time or availability, or implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.

(b) We make no warranties about the aamantran Service: We, our subsidiaries, affiliates and licensors do not warrant or represent that:

the aamantran Service will function uninterrupted or securely, or be available at any particular time or location;

any errors or defects will be corrected;

the aamantran Service is free of viruses or other harmful components; or

the results of using the aamantran Service will meet your requirements.

(c) Extent of this disclaimer: This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft, or destruction or unauthorized access or, alteration of or use of record in connection with the use or operation of the aamantran Service, whether for breach of contract, tortious behaviour, negligence or any other cause of action.

(d) We make no warranties about the content on the aamantran Service: We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the content contained on the aamantran Service for any purpose. Any reliance you place on such information is therefore strictly at your own risk. We disclaim any express or implied warranty, representation, or guarantee as to the effectiveness or profitability of the aamantran Service or that the operation of the aamantran Service will be uninterrupted or error-free. We are not liable for the consequences of any interruptions or error in the aamantran Service.

(e) We may provide access to third party services: We may at times include as part of or ancillary to the aamantran Service access to specific functionality or other services which are provided by third parties (for example, a payment portal).  

(f) We do not exclude the application of laws that cannot be excluded: These terms of service (including the above provisions of this clause 14) apply to the maximum extent permitted by law. Nothing in these terms of service is intended to exclude, restrict or modify rights which you may have under any law, which may not be excluded, restricted or modified by agreement. If these terms of service are governed by the Australian Consumer Law, our liability to you for failure to comply with a consumer guarantee in respect of any services is limited to the re-supply of the services or payment in lieu of such re-supply.

(g) Beta functionality: We may from time to time make certain functionality of the aamantran Service available to you in beta (and this functionality will be marked as ‘beta’ or similar). We are still evaluating and testing such beta functionality and it may not be as reliable as our other parts of the aamantran Service. 

(h) End user donations: End user agrees that donations made to a user, via aamantran’s ‘Support Me’ link, are made voluntarily and without exchange for any good or service. The service to process these payments is provided by a third party (see clause 15). 
<br />
<b>14. Third party services</b> 

(a) No endorsement of third party services: Unless we otherwise specifically state, we do not endorse (or make any warranties or representations in relation to) any third party product or service, and your use of that third party product or service may be subject to separate terms and conditions which you are responsible for reviewing and agreeing.

(b) Terms and conditions of third party services: You will comply with the terms and conditions that you have either separately agreed to comply with or that we make it clear to you that you must comply with of any third party service that you use in conjunction with the aamantran Service.  

(c) No refunds: We do not offer refunds for any amounts you have paid to a third party.

(d) Suspension or cancellation of account: If you do not agree with the third party terms and conditions, then we may suspend or cancel your user account or limit the relevant functionality.  
<br />
<b>15. Our rights to suspend etc. and issue resolution
</b> 
<br />
(a) Our rights to suspend or cancel your account: If you do not comply with any of these terms of service (in particular, clauses 7 (Acceptable Use), and 9 (Intellectual Property)), we may suspend or cancel your user account or limit the functionality of the aamantran Service that you have access to. Depending on what the non-compliance is, we may not use this right to suspend or cancel, but if there is repeat non-compliance on your account, or a material non-compliance, we are likely to.  

(b) Your rights to approach us with any issues: If you have any issues with our decision under clause 16(a), with the performance of the aamantran Service, or anything else in connection with these terms of service, please let us know by emailing us at support@aamantran.co.in (Issue Notice). Once we receive an Issue Notice, you and we will use all reasonable endeavours to resolve the issue in good faith. Neither of us will bring any legal proceedings or take any formal or public action in connection with the issue until we have spent at least 1 month from the date of the Issue Notice trying to resolve the issue in good faith. 
<br />
<b>16. General</b> 
<br />
(a) Entire agreement: These terms of service (along with the Privacy Policy) represent the entire agreement between you and us concerning your use and access to the Website and your use and access of the aamantran Service. No other term is to be included in these terms of service except where it is required to be included by any legislation. All implied terms except those implied by statute and which cannot be expressly excluded are expressly excluded.

(b) Severability: If any provision of these terms of service is invalid under the law of any jurisdiction, then the provision is enforceable in that jurisdiction to the extent that it is not invalid, and the to the extent it is not valid then it will be severed from these terms of service and the remainder of these terms of service will continue to apply with such amendments as are strictly necessary to reflect the severance.

(c) Governing law: These terms of service are governed by the laws of Victoria and the Commonwealth of Australia. Both you and we submit to the exclusive jurisdiction of the courts of Victoria and the Commonwealth of Australia.

(d) No waiver: Our failure to insist upon or enforce any provision of these terms of service will not be construed as a waiver of any provision or right.

(e) No agency: Neither these terms, nor the aamantran Service, create a relationship of agent / principal between you and us.
<br />
<b>17. Jurisdiction specific terms</b><br />
Laws may apply to you or to us as a result of  your specific location. If this is the case, and if those laws are inconsistent with any part of these terms, those laws will prevail to the extent of the inconsistency.

</p>
    </div>
</div>
  )
}

export default Tac