
import React,{useState} from 'react'
import {useDispatch} from 'react-redux'
import IconButton from '@mui/material/IconButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper';
import { ClickAwayListener } from '@mui/base';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PreviewContainer from './PreviewContainer';
import EndCropper from './EndCropper';
import { removeAvatar } from '../../../redux/reducers/endSlice';
const Avatar = ({endPhoto}) => {
  const dispatch=useDispatch()
    const [avatar,setAvatar]=useState(endPhoto&&endPhoto.url)
    const [open, setOpen] = React.useState(false);
    const [view,setView]=useState(false)
    const [showCropper,setShowCropper]=useState(false)
    const anchorRef = React.useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
      };
      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
      };
      function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        } else if (event.key === 'Escape') {
          setOpen(false);
        }
      }
      const handleView=()=>setView(prevValue=>!prevValue) 
      const handleCropper=()=>setShowCropper(prevValue=>!prevValue)
     
      const removeImage=async()=>{
        dispatch(removeAvatar({setAvatar}))
      }
      return (
   <>

     <div className='avatar-container'>
     <div className="avatar">
     <img src={avatar} alt="avatar"  />
     <IconButton  
           className='camera-icon'
            ref={anchorRef}
            onClick={handleToggle}
            id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-haspopup="true"
             >
            
            <CameraAltIcon/>
            </IconButton>
     </div>
     <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                  <MenuItem onClick={(event)=>{
                     handleView()
                    handleClose(event); }}>VIEW</MenuItem>
                   
                    <MenuItem onClick={(event)=>{
                      handleCropper()
                    handleClose(event); }}>CHANGE</MenuItem>
                     <MenuItem onClick={(event)=>{
                      removeImage()
                    handleClose(event); }}>REMOVE</MenuItem>
                   
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

     </div>
     {view && <PreviewContainer handleView={handleView} avatar={avatar} />  }
     {showCropper &&  <EndCropper handleCropper={handleCropper}  setAvatar={setAvatar}  avatar={avatar}    />   }
   </>
  )
}

export default Avatar