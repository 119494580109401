import React,{useEffect, useState} from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import inputErrors from '../../helpers/validation'
import { useDispatch,useSelector } from 'react-redux';
import { changePass,clearError } from '../../../redux/reducers/authSlice';
import {snackbarContext} from '../../helpers/Snakbar'
const ChangePassword = () => {
    const dispatch=useDispatch()
    const setStateSnackbarContext=React.useContext(snackbarContext)
    const {error:backendError}=useSelector((state)=>state.auth)
    const [errors, setErrors] = useState({});
    const [values,setValues]=useState({
        oldPassword:"",
        password:"",
        confirmPassword:""
    })
    const [isSubmitting, setSubmitting] = useState(false);
    const handleSubmit=(e)=>{
       e.preventDefault()
       const validationErrors=inputErrors(values)
       setErrors(validationErrors)
       setSubmitting(true);
    }
    const handleChange=(event)=>{
        setValues({
            ...values,
            [event.target.name]: event.target.value
          });    
    }

    const request=()=>{
         setSubmitting(false)
         dispatch(changePass({values,setStateSnackbarContext,setValues}))
    }

    useEffect(()=>{
        if(isSubmitting){
            const noErrors = Object.keys(errors).length === 0;   
            if(noErrors){
                request()
             }
        }

        if(backendError){
            setStateSnackbarContext(
              true,
              backendError,
              "error"
            )
            dispatch(clearError())
          }
        
    },[isSubmitting,backendError,errors])
  return (
     <form onSubmit={handleSubmit}>
           <div className={errors.oldPassword?"error-update-email-box":"update-email-box"}>
            <input type="password"
            id="oldPassword"
            value={values.oldPassword}
            name='oldPassword'
            placeholder=' '
            className='update-input-feild'
                 onChange={handleChange}
             />
             <label htmlFor="oldPassword" className="update-email-label" >Old Password</label>
             <span className="update-label-icon"><LockIcon className='update-icon'/></span>
                <span className="error-update-label-icon"><ErrorIcon className='update-icon'/></span>
           </div>
           <div>  {errors.oldPassword &&  ( <small className="error-msg">{errors.oldPassword}</small>) }</div>
    
           <div className={errors.password?"error-update-email-box":"update-email-box"}>
            <input type="password"
            id="password"
            value={values.password}
            name='password'
            placeholder=' '
            className='update-input-feild'
                 onChange={handleChange}
             />
             <label htmlFor="password" className="update-email-label" >New  Password</label>
             <span className="update-label-icon"><LockIcon className='update-icon'/></span>
                <span className="error-update-label-icon"><ErrorIcon className='update-icon'/></span>
           </div>
           <div>  {errors.password &&  ( <small className="error-msg">{errors.password}</small>) }</div>

           <div className={errors.confirmPassword?"error-update-email-box":"update-email-box"}>
            <input type="password"
            id="confirmPassword"
            value={values.confirmPassword}
            name='confirmPassword'
            placeholder=' '
            className='update-input-feild'
                 onChange={handleChange}
             />
             <label htmlFor="confirmPassword" className="update-email-label" >Confirm Password</label>
             <span className="update-label-icon"><LockIcon className='update-icon'/></span>
                <span className="error-update-label-icon"><ErrorIcon className='update-icon'/></span>
           </div>
           <div>  {errors.confirmPassword &&  ( <small className="error-msg">{errors.confirmPassword}</small>) }</div>
          
           <button type='submit' className='update-button'>Update</button>
     </form>
  )
}

export default ChangePassword