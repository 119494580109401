import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../components/helpers/Axios";


export const getAllLinks=createAsyncThunk("links/all",async(data,{rejectWithValue})=>{
    try {
         const res=await axios.get("/links")
         return res.data.user
    } catch (error) {

     return rejectWithValue(error.response.data.message) 
    }
})

export const addLink=createAsyncThunk("link/add",async({values,setValues,setStateSnackbarContext},{rejectWithValue})=>{
    try {
         await axios.post("/addlink",values)
        setValues({
            linkTitle:"",
            url:""
        })
        setStateSnackbarContext(
            true,
            "link added success",
            "success"
        )
      
    } catch (error) {

     return rejectWithValue(error.response.data.message) 
    }
})


export const deleteLink=createAsyncThunk("link/delete",async({id,setStateSnackbarContext},{rejectWithValue})=>{
    try {
        await axios.delete(`/link/${id}`)
        setStateSnackbarContext(
            true,
            "Link Deleted Success",
            "success"
        )
    } catch (error) {

        return rejectWithValue(error.response.data.message) 
    }
})

export const reorderLink=createAsyncThunk("links/redorder",async(links,{rejectWithValue})=>{
    try {
        const res=await axios.post(`/links/reorder`,links)
        return res.data.user
    } catch (error) {

        return rejectWithValue(error.response.data.message) 
    }
})


export const editLink=createAsyncThunk("link/edit",async({values,toggleEditTitle,editTitle,toggleEditURL,editURL},{rejectWithValue})=>{
    try {
      
        const res=await axios.put(`/editlink`,values)
        if(res.data.message==="link edited sucess"){
            if(editTitle===true){
              toggleEditTitle()
            }
            if(editURL===true){
              toggleEditURL()
            }
        }
    } catch (error) {

        return rejectWithValue(error.response.data.message) 
    }
})

export const uploadAvatar=createAsyncThunk("avatar/upload",async({formdata,handleCropper,setAvatar,avatar,setShowBackDrop,setStateSnackbarContext},{rejectWithValue})=>{
    try {
     const config={headers:{"Content-Type":"multipart/form-data"}}
     const res=await axios.post(`/upload/avatar`,formdata,config)
     setAvatar(res.data.user.endPhoto.url)
     setShowBackDrop(false)
   
     handleCropper()
     setStateSnackbarContext(
         true,
         `avatar upload success`,
         "success"
       )
     return res.data.user
    } catch (error) {
     return rejectWithValue(error.response.data.message) 
    }
 })


 export const updateProfile=createAsyncThunk("udate/profile",async({values,editBrideGroomName,setEditbrideGroomName,editHashtag,setEditHashtag,editMessage,setEditMessage},{rejectWithValue})=>{
     try {
        const res=await axios.post("/update/profile",values)
        if(res.data.message==="profile updated success"){
            if(editBrideGroomName===true){
                setEditbrideGroomName(false)
            }
            if(editHashtag===true){
                setEditHashtag(false)
            }
            if(editMessage){
                setEditMessage(false)
            }

        }

        return res.data.user
     } catch (error) {

        return rejectWithValue(error.response.data.message)
     }
 })

 export const removeAvatar=createAsyncThunk("avatar/remove",async({setAvatar},{rejectWithValue})=>{
    try {
         const res=await axios.get("/avatar/remove")
         setAvatar(res.data.user.endPhoto.url)
          return res.data.message
    } catch (error) {

        return rejectWithValue(error.response.data)
    }
 })


 export const getEndProfile=createAsyncThunk("endprofile/id",async({id},{rejectWithValue})=>{
    try {
        const res=await axios.get(`/endprofile/details/${id}`)
         return res.data.user
    } catch (error) {

        return rejectWithValue(error.response.data)
    }
 })




const endSlice=createSlice({
    name:"endProfile",
    initialState:{
        loading:false,
        endProfile:{},
        error:null
    },
    reducers:{
        clearError:(state)=>{
            state.error=null;
         }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllLinks.pending,(state)=>{
            state.loading=true
        })
        .addCase(getAllLinks.fulfilled,(state,action)=>{
            state.loading=false;
            state.endProfile=action.payload
        })
        .addCase(getAllLinks.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(addLink.pending,(state)=>{
            state.loading=true
        })
        .addCase(addLink.fulfilled,(state,action)=>{
            state.loading=false;
        })
        .addCase(addLink.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(deleteLink.pending,(state)=>{
            state.loading=true
        })
        .addCase(deleteLink.fulfilled,(state,action)=>{
            state.loading=false;
        })
        .addCase(deleteLink.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(reorderLink.pending,(state)=>{
            state.loading=true
        })
        .addCase(reorderLink.fulfilled,(state,action)=>{
            state.loading=false;
            state.endProfile=action.payload
        })
        .addCase(reorderLink.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })


        .addCase(uploadAvatar.pending,(state)=>{
            state.loading=true
        })
        .addCase(uploadAvatar.fulfilled,(state,action)=>{
            state.loading=false;
            state.endProfile=action.payload
        })
        .addCase(uploadAvatar.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })


        .addCase(updateProfile.pending,(state)=>{
            state.loading=true
        })
        .addCase(updateProfile.fulfilled,(state,action)=>{
            state.loading=false;
            state.endProfile=action.payload
        })
        .addCase(updateProfile.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(getEndProfile.pending,(state)=>{
            state.loading=true
        })
        .addCase(getEndProfile.fulfilled,(state,action)=>{
            state.loading=false;
            state.endProfile=action.payload
        })
        .addCase(getEndProfile.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

    }
})

export const {clearError}=endSlice.actions

export default endSlice.reducer