import React,{useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { deleteEvent, getMyEvents,clearError,reorderList } from '../../../redux/reducers/eventSlice'
import './eventPage.css'
import {useNavigate} from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import {snackbarContext} from '../../helpers/Snakbar'
import dayjs from 'dayjs';
import { clearUser } from '../../../redux/reducers/authSlice';
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EventIcon from '@mui/icons-material/Event';
import Navbar from '../../helpers/Navbar';
import { TabTitle } from '../../helpers/GeneralFunctions'
import Loader from '../../helpers/Loader'
const EventPage = () => {
  TabTitle("My Events")
  const setStateSnackbarContext=React.useContext(snackbarContext)
   const navigate=useNavigate()
  const dispatch=useDispatch()
  const [open, setOpen] = useState(false);
  const {user} = useSelector(state => state.auth);
  const [deleteEventId, setDeleteEventId] = useState(null);
  const {events,error:backendError,loading}=useSelector((state)=>state.event)

  

  const fetchEVents=()=>{
      dispatch(getMyEvents())
  }


  useEffect(()=>{
  fetchEVents()

  },[])
 

  useEffect(()=>{
    if (backendError === "session expired" || backendError==="pls login to acess the resource") {
      setStateSnackbarContext(true, "session expired please login again", "error");
      dispatch(clearError());
      dispatch(clearUser())
      navigate("/login")
    } else if (backendError) {
      setStateSnackbarContext(true, backendError, "error");
      dispatch(clearError());
    }
  },[backendError])


  if(user && user.hasEnded){
    navigate("/display/notfound")
    }
    

  const handleClickOpen = (id) => {
    setDeleteEventId(id); // Set the id of the event to be deleted
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteEventId(null);
    setOpen(false);
  };

  const deleteFunc=()=>{
    const id=deleteEventId
   dispatch(deleteEvent({id,setStateSnackbarContext})).then(() => {
      dispatch(getMyEvents());
  });
   setDeleteEventId(null);
    setOpen(false)
  }

  const onDragEnd=(result)=>{
    if(!result.destination) return;
    const newItems = Array.from(events);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed)
     dispatch(reorderList(newItems))
  }

  if(loading){
    return <Loader/>
  }


  
  return (
  
  <>
  <Navbar/>
  <div className="display-container">
  <h1 className='events-heading'>My Events</h1>
  
    <DragDropContext onDragEnd={onDragEnd}>
    <div  > 
    <Droppable droppableId="droppable-1" >
    {(provided)=>(
      <div ref={provided.innerRef}  {...provided.droppableProps}   >
      {events && (events.length>0)? events.map((event,i)=>(
         <>
        <Draggable key={event?._id} draggableId={"draggable-"+(event?._id || i)} index={i}  > 
        {((provided,snapshot)=>(
        
        <div className="display-card"  ref={provided.innerRef}  {...provided.draggableProps} style={{...provided.draggableProps.style,boxShadow:snapshot.isDragging?"0 0 .4rem #666":"none"}}>
  <div className="drag-div" {...provided.dragHandleProps}>
      <DragIndicatorIcon className='drag-icon'/>
  </div>
  <div className="event-icon-div">
    <EventIcon className='event-icon'/>
  </div>
  
  
  <div className="event-details">
     <p>Name:{event?.eventName}</p>
     <p>Time:{event?.eventTime ? event.eventTime : 'NA'}</p>
     <p>Date:{dayjs(event?.eventDate).format('DD-MM-YYYY')}</p>
  </div>
  <div className="event-action-div">
   <CreateIcon className='action-button edit' onClick={() => navigate(`/event/${event._id}`)} />
   <DeleteForeverIcon className='action-button delete'  onClick={() => handleClickOpen(event._id)}/>

   <Dialog
                          open={open && deleteEventId === event._id}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{'Are you Sure?'}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Once deleted cannot be recovered back
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={() => deleteFunc(event._id)} autoFocus>
                              Agree
                            </Button>
                          </DialogActions>
                        </Dialog>
  </div>
</div>
   ))}
</Draggable>

</>
  )):<div className='noEvents-div'>
    <img src="addevent.png" alt="" />
    <h3>You Have No Events</h3>
    <button onClick={()=>navigate("/event/new")}>Add Event</button>
  </div>}
 
      </div>
    )}
    
</Droppable>
</div>
</DragDropContext>



{events.length>0&&<button className='newEventButton' onClick={()=>navigate("/event/new")}>Add New Event</button> }
</div>
</>




  )
}

export default EventPage



