import {configureStore} from '@reduxjs/toolkit'
import authSlice from './reducers/authSlice';
import eventSlice from './reducers/eventSlice';
import profileSlice from './reducers/profileSlice';
import endSlice from './reducers/endSlice';
const store=configureStore({
    reducer:{
       event:eventSlice,
       auth:authSlice,
       profile:profileSlice,
       end:endSlice
    }
})


export default store;