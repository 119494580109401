
import React,{useEffect,useState} from 'react'
import './appreance.css'
import Avatar from './Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLinks, updateProfile ,clearError } from '../../../redux/reducers/endSlice'
import { Button } from 'react-bootstrap'
import inputErrors from '../../helpers/validation'
import Navbar from '../../helpers/Navbar'
import SubNavigation from './SubNavigation'
import { useNavigate } from 'react-router-dom'
import { snackbarContext } from '../../helpers/Snakbar'
import { clearUser } from '../../../redux/reducers/authSlice'
import { TabTitle } from '../../helpers/GeneralFunctions'
import Footer from '../../helpers/Footer'
const Appreance = () => {
  TabTitle("Appreance")
  const navigate=useNavigate()
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const {user} = useSelector(state => state.auth);
  const {endPhoto,brideGroomName,hashtag,message}=useSelector(state=>state.end.endProfile)

  const {endProfile,error:backendError}=useSelector(state=>state.end)
    
  const dispatch=useDispatch()
  const [editBrideGroomName,setEditbrideGroomName]=useState(false)
  const [editHashtag,setEditHashtag]=useState(false)
  const [editMessage,setEditMessage]=useState(false)
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({
    brideGroomName: "",
    hashtag:"",
    message:""
});

const [errors, setErrors] = useState({});
  useEffect(()=>{
    dispatch(getAllLinks())
   },[])

   const handleChange=(event)=>{
    setValues({
        ...values,
        [event.target.name]: event.target.value
      });    
    }

    const handleSubmit=(e)=>{
     
      e.preventDefault();
     const validationErrors = inputErrors(values);
     setErrors(validationErrors)
     setSubmitting(true);
   }
   const reqFunc=async()=>{
    setSubmitting(false)
  
     dispatch(updateProfile({values,editBrideGroomName,setEditbrideGroomName,editHashtag,setEditHashtag,editMessage,setEditMessage}))

   }

   useEffect(()=>{
    if(isSubmitting){
      const noErrors = Object.keys(errors).length === 0;   
      if(noErrors){
        reqFunc()
      }
    }
    if (backendError === "session expired"  || backendError==="pls login to acess the resource") {
      setStateSnackbarContext(true, "session expired please login again", "error");
      dispatch(clearError());
      dispatch(clearUser())
      navigate("/login")
    } else if (backendError) {
      setStateSnackbarContext(true, backendError, "error");
      dispatch(clearError());
    }
   },[isSubmitting,errors,backendError])

   if(user && !user.hasEnded){
    navigate("/")
    }


const toggleEditName=()=>{
  setEditbrideGroomName(!editBrideGroomName)
 }

 const toggleEditHashtag=()=>{
  setEditHashtag(!editHashtag)
 }

 const toggleEditMessage=()=>{
  setEditMessage(!editMessage)
 }

 useEffect(() => {
  setValues({ 
  brideGroomName:endProfile.brideGroomName?endProfile.brideGroomName:"",
  hashtag:endProfile.hashtag?endProfile.hashtag:"",
  message:endProfile.message?endProfile.message:""
  })
}, [endProfile])




  return (
    <>
    <Navbar/>
    <SubNavigation/>
 <div className='container profile-container'>
    <div className="row">
      <div className="col-lg-6 col-md-6">  
     {endPhoto &&  <Avatar endPhoto={endPhoto}/> } 
      </div>
      <div className="col-lg-6 col-md-6 detail-container">
        <h3>DETAILS:</h3>
       
        <h6 className='detail-heading'>BRIDE AND GROOM NAME <span style={{ color: "red" }}> *</span></h6>
        <small style={{textTransform:"capitalize"}}><b>(eg BrideName & GroomName)</b></small>
        <div className="detail-feild">
        {(editBrideGroomName)?
        <form>
          <input type="text"
          name='brideGroomName'
          value={values.brideGroomName}
          onChange={handleChange}
          autoFocus="autofocus"
            onBlur={handleSubmit} 
            className={errors.brideGroomName ? 'error-url-text' : 'detail-link-input'}
            placeholder='bride and groom name'
           />
        </form>
        :brideGroomName?brideGroomName:"Bride and Groom Name"}
        <span className='edit-button' onClick={toggleEditName}>
          {(editBrideGroomName)?"":<Button  type="button" variant="no" ><i className="far fa-edit"></i></Button>}
        </span>
        <div className='error-msg-appreance'>  {errors.brideGroomName &&  ( <small className="error-msg-profile">{errors.brideGroomName}</small>) }</div>
        </div>

        <h6 className='detail-heading'>HASHTAG</h6>
        <div className="detail-feild">
        {(editHashtag)?
        <form>
          <input type="text"
          name='hashtag'
          value={values.hashtag}
          onChange={handleChange}
          autoFocus="autofocus"
            onBlur={handleSubmit} 
            className={errors.hashtag ? 'error-url-text' : 'detail-link-input'}
            placeholder='hashtag'
           />
        </form>
        :hashtag?hashtag:"Hashtag"}
        <span className='edit-button' onClick={toggleEditHashtag}>
          {(editHashtag)?"":<Button  type="button" variant="no" ><i className="far fa-edit"></i></Button>}
        </span>
        <div className='error-msg-appreance'>  {errors.hashtag &&  ( <small className="error-msg-profile">{errors.hashtag}</small>) }</div>
        </div>

        <h5 className='detail-heading'>SPECIAL MESSAGE</h5>
        <small style={{textTransform:"capitalize"}}><b>(Give a special message) </b></small>
        <div className="bio-container mt-3">
           {(editMessage)?  <form>
            <textarea 
            name="message" 
             cols="70" 
             rows="6"
             id='bio'
             value={values.message}
             onChange={handleChange}
             autoFocus="autofocus" 
             onBlur={handleSubmit} 
             className={errors.message ? 'error-url-text' : 'detail-link-input'}
             ></textarea>
           </form>:message?message:"Special Message"}
           <span className='edit-button' onClick={toggleEditMessage}>
          {(editMessage)?"":<Button  type="button" variant="no" ><i className="far fa-edit"></i></Button>}
        </span>
        <div className='error-msg-appreance'>  {errors.message &&  ( <small className="error-msg-profile">{errors.message}</small>) }</div>
        </div>
        </div>
      </div>

   
  </div>
  <Footer userCode={user&& user.userCode} />
</>
  )
  
}

export default Appreance