
import React, { useEffect, useState } from 'react'
import './ended.css'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEndProfile } from '../../../redux/reducers/endSlice'
import { TabTitle } from '../../helpers/GeneralFunctions'
import Esharing from './Esharing'
import Loader from '../../helpers/Loader'
const EndedOutput = () => {
   const {loading,endProfile}=useSelector((state)=>state.end)

   const [values,setValues]=useState({
    endPhoto:"",  
    brideGroomName:"",
    hashtag:"",
    message:"",
    links:""
   })
   TabTitle( (values.brideGroomName)?`${values.brideGroomName} - Wedding Invitation`  :"Aamantran Wedding Invitation")
  const params=useParams()
  const dispatch=useDispatch()
  const id=params.username
  useEffect(()=>{
    dispatch(getEndProfile({id}))
  },[id])

  useEffect(()=>{
    setValues({...endProfile})
    },[endProfile])

    if(loading){
      return <Loader/>
    }

    
  return (
    <div className='end-container'>
    
       <div className="preview-card">
       <Esharing brideGroomName={values.brideGroomName} />
       <h1>Thanks For Your Visit</h1>
       <div className='preview-details'>
       <img  src={values.endPhoto ? values.endPhoto.url : ''} alt="avatar"  />
       <div className='profile-content'>
       <h1>{values.brideGroomName}</h1>
        {values.hashtag && <h3>#{values.hashtag}</h3> }
       {values.message &&  <p>{values.message}</p>} 
       </div>
       </div>
       <h2>Wedded Bliss : Our Unforgettable Wedding Memories</h2>
       <div className="links-list">
        {values.links && (values.links.length>0)? 
           values.links.map((link,i)=>(
            <a  className='end-link-container' href={link.url} target="_blank" rel="noopener noreferrer">
        <div className="single-link"><i className="fas fa-link"></i><span>{link.linkTitle}</span></div>
       </a>
           ))
           :<h3>No links Added !!</h3>}
       
       
      </div>

      <div className="end-footer">
            <h3>Created with ❤️ at Aamantran</h3>
           </div>
       </div>
      
    </div>
  )
}

export default EndedOutput