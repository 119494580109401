import React from 'react'
import { StepLabel, Stepper, Typography,Step } from '@mui/material';

const Progress = ({activeStep}) => {
    const steps = [
        {
          label: <Typography>Banner Deatils</Typography>,
        
        },
        {
          label: <Typography>Bride Groom Details</Typography>,
         
        },
        {
          label: <Typography>Additional Details</Typography>,
         
        },
      ];
      const stepStyles = {
        boxSizing: "border-box",
        marginTop:"20px"
      };
    return (
   <>
     <Stepper alternativeLabel activeStep={activeStep} style={stepStyles}>
       {steps.map((item,index)=>(
        <Step key={index}  active={activeStep===index?true:false}  completed={activeStep>=index?true:false} >
         <StepLabel   style={{
                color: activeStep >= index ? "brown" : "rgba(0, 0, 0, 0.649)",
              }}>{item.label} </StepLabel>
        </Step>
       ))}
     </Stepper>
   </>
  )
}

export default Progress