import React from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { TabTitle } from './GeneralFunctions'

const Display = () => {
  const params=useParams()
  const navigate=useNavigate()
  const screen=params.screen
  TabTitle(`${screen}`)
  return (
    <div className="error-container">
        <div className="error-box">
        {screen === 'warning' ? (
          <>
            <img src="https://res.cloudinary.com/depixc8dq/image/upload/v1718954848/aamantran%20assets/warning_lsbap0.png" alt="warning" />
            <h3>Try Again session Expired</h3>
            <button onClick={() => navigate('/')}>Back to Home Page</button>
          </>
        ) : null}

        {screen === 'success' ? (
          <>
            <img src="https://res.cloudinary.com/depixc8dq/image/upload/v1718954848/aamantran%20assets/success_sxatoe.png" alt="success" />
            <h3>Email was successfully updated</h3>
            <button onClick={() => navigate('/')}>Back to Home Page</button>
          </>
        ) : null}

        {screen === 'notfound' ? (
          <>
            <img src="https://res.cloudinary.com/depixc8dq/image/upload/v1718954847/aamantran%20assets/notfound_x0boee.png" alt="notfound" />
            <h3>Page does not exist</h3>
          </>
        ) : null}
        </div>
    </div>
  )
}

export default Display