import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = () => {
  const { loading, isAuthenticated, } = useSelector(state => state.auth);
  const navigate = useNavigate();
     
  if (loading) {
    // You can add a loading spinner or other UI here if needed.
    return null;
  }
 
  if (!isAuthenticated) {
    navigate('/login');
    return null;
  }
  return <Outlet />;
};

export default ProtectedRoute;
