import React, { useState ,useEffect} from 'react'
import './setting.css'
import MailIcon from '@mui/icons-material/Mail';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from 'react-redux';
import inputErrors from '../../helpers/validation'
import { updateEmail,clearError,clearUser} from '../../../redux/reducers/authSlice';
import {snackbarContext} from '../../helpers/Snakbar'
import ChangePassword from './ChangePassword';
import Navbar from '../../helpers/Navbar';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../helpers/GeneralFunctions';
import Loader from '../../helpers/Loader'
import Footer from '../../helpers/Footer';
const Setting = () => {
  TabTitle("Settings")
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const {error:backendError,user,loading}=useSelector((state)=>state.auth)
    const [errors, setErrors] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [values,setValues]=useState({
        "email":""
    })
    const handleSubmit=(e)=>{
      e.preventDefault()
      const validationErrors=inputErrors(values)
      setErrors(validationErrors)
      setSubmitting(true);
    }
    useEffect(() => {
      // Update the email value when the user changes
      setValues((prevValues) => ({
        ...prevValues,
        email: user && user.email,
      }));
    }, [user]);
    const handleChange=(event)=>{
        setValues({
            ...values,
            [event.target.name]: event.target.value
          });    
    }

    const request=()=>{
      setSubmitting(false)
      dispatch(updateEmail({values,setStateSnackbarContext}))
    }

    useEffect(()=>{
      if(isSubmitting){
        const noErrors = Object.keys(errors).length === 0;   
        if(noErrors){
            request()
         }
    }
    if (backendError === "session expired") {
      setStateSnackbarContext(true, "session expired please login again", "error");
      dispatch(clearError());
      dispatch(clearUser())
      navigate("/login")
    } else if (backendError) {
      setStateSnackbarContext(true, backendError, "error");
      dispatch(clearError());
    }
    },[isSubmitting,backendError,errors])
    
    if(loading){
      return <Loader/>
    }
  return (
    <>
    <Navbar/>
    <div className="container">
       <div className="row justify-content-center ">
       <div className="col-lg-8 d-flex flex-column ">
       <h1 className="text-center m-4">SETTINGS</h1>
       <h3 className='m-3 subheading'>Update Email :</h3>
       <form onSubmit={handleSubmit}>
             <div className={errors.email?"error-update-email-box":"update-email-box"}>
               <input type="text" 
                id='email'
                value={values.email}
                name='email'
                placeholder=' '
                className='update-input-feild'
                 onChange={handleChange}
               />
                <label htmlFor="email" className="update-email-label" >Update Email</label>
                <span className="update-label-icon"><MailIcon className='update-icon'/></span>
                <span className="error-update-label-icon"><ErrorIcon className='update-icon'/></span>
             </div>
             <div>  {errors.email &&  ( <small className="error-msg">{errors.email}</small>) }</div>
             <button type='submit' className='update-button'>Update</button>
            
       </form>
       <h3 className='m-3 mt-5 subheading'>Change Password :</h3>
       <ChangePassword/>
       </div>
       </div> 
    </div>
    <Footer userCode={user&&user.userCode}/>
    </>
  )
}

export default Setting