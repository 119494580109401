import React from 'react'

const Theme = ({activeTheme,theme,rerender,url}) => {
    
    const isActive = () => {
        if (activeTheme === theme) {
          return 'active-theme';
        }
        return 'default-theme';
      }
  const submit=()=>{
   rerender(theme)
  }
  return (
    <div className={`${isActive()}`}>
  <div className="theme-box" onClick={submit}>
     <img src={url} alt="" />
  </div>
    </div>
  )
}

export default Theme