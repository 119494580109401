import React from 'react'
const Background = ({activeBg,bg,rerenderBg,handleDefaultBg}) => {
  
    const isActive = () => {
        if (activeBg === bg) {
          return 'active-theme';
        }
        return 'default-theme';
      }
  const submit=()=>{
   rerenderBg(bg)
   handleDefaultBg()
  }
  return (
    <div className={`${isActive()}`}>
  <div className="box" onClick={submit}>
     <img src={bg} alt="" />
  </div>
    </div>
  )
}

export default Background