import React,{useEffect, useRef} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {useLoadScript} from '@react-google-maps/api'
import './map.css'
import geolocation from 'geolocation';
const libraries = ["places"];
const MapInput = ({setValues,values,update}) => {

    const {isLoaded}=useLoadScript({googleMapsApiKey:process.env.REACT_APP_GOOGLEAPI,libraries})
    const searchInput = useRef()


  const apiKey = process.env.REACT_APP_GOOGLEAPI;
  const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';



    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        
        const location={
            latitude:place.geometry.location.lat(),
            longitude:place.geometry.location.lng(),
            formatted_address:place.formatted_address
        }
        setValues((values) => ({
            ...values,
             gLocation:location
         }));
      }

    const initAutocomplete = () => {
        if (!searchInput.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current,{
          types: ["establishment"],
          componentRestrictions: { country: "IN" } // "IN" is the country code for India
        });
        autocomplete.setFields(["address_component", "geometry","formatted_address"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
      }

      useEffect(()=>{
       if(isLoaded){
        initAutocomplete()
       }
      },[isLoaded])

      const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
        const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
        searchInput.current.value = "Getting your location...";
        fetch(url)
          .then(response => response.json())
          .then(location => {
            const place = location.results[0];
            searchInput.current.value = place.formatted_address;
            const locationObject={
                latitude:place.geometry.location.lat,
                longitude:place.geometry.location.lng,
                formatted_address:place.formatted_address
            }
            setValues((values) => ({
                ...values,
                 gLocation:locationObject
             }));
           
          })
      }

      const findMyLocation = () => {
        geolocation.getCurrentPosition((err, position) => {
          if (!err) {
            reverseGeocode(position.coords)
          }
        })
      }
    

  return (
    <div className='search'>
         <span className='map-search'><SearchIcon/></span>
        <input ref={searchInput} type="text" name="" id=""   placeholder={update?"Search To Update Your Location....":"Search Your Location....."} />
         <span className='gps-search'  onClick={findMyLocation}><GpsFixedIcon/></span>
    </div>
  )
}

export default MapInput