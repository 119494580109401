import React from 'react'
import {NavLink} from 'react-router-dom'
const SubNavigation = () => {
  return (
    <div className="navigate-header">
 <NavLink className="navigate-links" activeClassName='navlink-active' to="/links"  >LINKS</NavLink>
              <NavLink className="navigate-links" activeClassName='navlink-active' to="/appreances"  >Appreance </NavLink>
    </div>
   
  )
}

export default SubNavigation