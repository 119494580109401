import React,{useState,useEffect} from 'react'
import Navbar from '../../helpers/Navbar'
import './endInvite.css'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowForward from '@mui/icons-material/ArrowForward'
import {useDispatch,useSelector} from 'react-redux'
import TextField from '@mui/material/TextField';
import { endInvite, logout } from '../../../redux/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import {snackbarContext} from '../../helpers/Snakbar'
import { TabTitle } from '../../helpers/GeneralFunctions';
import Footer from '../../helpers/Footer';
const EndHome = () => {
  TabTitle("End Invitation")
    const setStateSnackbarContext=React.useContext(snackbarContext)
    const [open, setOpen] = useState(false);
    const {hasEnded,userCode}=useSelector((state)=>state.auth.user)
    const [inputValue, setInputValue] = useState('');
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const handleClickOpen = () => {
        setOpen(true);
      };

      
  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleAgree = () => {
      dispatch(endInvite())
      setOpen(false);
      setStateSnackbarContext(
        true,
        "invitation ended success Pls Login again",
        "success"
      )
      dispatch(logout({navigate,setStateSnackbarContext,end:true}))

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  if(hasEnded){
    window.location.href = "/display/notfound";
  }

 
    return (
        <>
            <Navbar />
            <h3 className='end-heading'>End Your Invite ?</h3>
            <div className="end-home-div">
                <img src="https://res.cloudinary.com/depixc8dq/image/upload/v1718954847/aamantran%20assets/end_igwqon.jpg" alt="" className='end-image' />
                <div className="end-points">
                    <h3>The Saga Continues: Post-Invitation Edition</h3>
                    <ul>
                        <li>
                            <strong>Access Restrictions:</strong>
                            <ul>
                                <li>Once you finalize and close your invitation, access to pages related to invitation creation will be restricted. For eg Pages like:-My events,create new Events and wedding details page will be restricted</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Editing Thanks Page:</strong>
                            <ul>
                                <li>Access will be limited to a single page containing a form for editing your "Thanks for Visiting" page.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Final Output:</strong>
                            <ul>
                                <li>The final output will display only links and a thank-you message, replacing the original invitation.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Free Access:</strong>
                            <ul>
                                <li>The page will remain valid for free and will replace the initial invitation.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Visual Presentation of Links:</strong>
                            <ul>
                                <li>Utilize the page to add links such as photos, drive links, pre-wedding links, and other essential links. These will be presented in a visually appealing manner after the main image.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Post-Wedding Link Collection:</strong>
                            <ul>
                                <li>This page serves as a centralized location for sharing links after your wedding, making it convenient for users to access and collect all relevant links in one place.</li>
                            </ul>
                        </li>
                    </ul>
                    <button onClick={handleClickOpen}>End Your Invitation <ArrowForward className='end-icon' /> </button>
                    <Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{'Are you Sure?'}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Please confirm the action by typing "end" in the input field. Once ended, it cannot be reverted back.
    </DialogContentText>
    <TextField
      autoFocus
      margin="dense"
      id="endConfirmation"
      label="Type 'end' to confirm"
      type="text"
      fullWidth
      onChange={(e) => handleInputChange(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Disagree</Button>
    <Button disabled={inputValue.toLowerCase() !== 'end'} autoFocus onClick={() => handleAgree()}>
      Agree
    </Button>
  </DialogActions>
</Dialog>
                </div>
            </div>
            <Footer userCode={userCode}/>
        </>
    )
}

export default EndHome