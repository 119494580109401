import React from 'react'
import {GoogleMap,useLoadScript,Marker} from '@react-google-maps/api'
const libraries=["places"]
const GoogleMapDiv = ({latitude,longitude}) => {
  const {isLoaded}=useLoadScript({googleMapsApiKey:process.env.REACT_APP_GOOGLEAPI,libraries})

  const mapOptions = {
    disableDefaultUI: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: isLoaded && window.google.maps.ControlPosition.LEFT_BOTTOM
    },
    zoomControl: true,
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  const mapStyles = {
    width: '280px',
    height: '250px',
    borderRadius:"10px"
  };

  const openGoogleMaps = () => {
    const latLng = `${latitude},${longitude}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${latLng}`;
    window.open(url, '_blank');
  };
  return (
   <GoogleMap
   options={mapOptions}
   zoom={12}
   center={{lat:latitude,lng:longitude}}
   mapContainerStyle={mapStyles}
   >

   
    <Marker position={{ lat: latitude, lng: longitude }}   />
    <div
               style={{
                 position: 'absolute',
                 top: '20px',
                 left: '20px',
                 backgroundColor: 'rgba(255, 255, 255, 0.9)',
                 border: '2px solid #ccc',
                 borderRadius: '5px',
                 boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                 cursor: 'pointer',
                 textAlign: 'center',
                 width: '150px',
                 height: '40px',
                 zIndex: 1,
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
               }}
             >
               <button onClick={openGoogleMaps}  style={{
                 background: "blue",
                  width: "100%",
                 height: "100%",
                 color: 'white',
                  border: 'none',
                 borderRadius: '5px',
                 fontWeight: "bolder",
                  cursor: 'pointer',
               }}>Get Directions</button>
             </div>
   </GoogleMap>
  )
}

export default GoogleMapDiv