
import React, { useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Button} from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { deleteLink, editLink, getAllLinks } from '../../../redux/reducers/endSlice';
import {snackbarContext} from '../../helpers/Snakbar'
import inputErrors from '../../helpers/validation';
const EditableLink = ({ link,provided,snapshot }) => {
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const dispatch=useDispatch()
  const [editTitle, setEditTitle] = useState(false);
  const [editURL, setEditURL] = useState(false);
  const [errors, setErrors] = useState({})
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({
    id:link._id,
    linkTitle: link.linkTitle,
    url: link.url,
});
  
  const toggleEditTitle = () => {
    setEditTitle(!editTitle);
  }
  const toggleEditURL = () => {
    setEditURL(!editURL);
  }

  const deleteFunc=async(id)=>{
    await  dispatch(deleteLink({id,setStateSnackbarContext}))
   dispatch(getAllLinks())
  }
  const handleChange=(event)=>{
    setValues({
        ...values,
        [event.target.name]: event.target.value
      });    
}



const handleSubmit=(e)=>{
   e.preventDefault()
  const validationErrors = inputErrors(values);
  setErrors(validationErrors)
  setSubmitting(true)
}

const reqFunc=async()=>{
  setSubmitting(false)
  values.id=link._id
 await dispatch(editLink({values,toggleEditTitle,editTitle,toggleEditURL,editURL}))
 dispatch(getAllLinks())
}

useEffect(()=>{
  if(isSubmitting){
    const noErrors = Object.keys(errors).length === 0;   
    if(noErrors){
      reqFunc()
    }
}

},[isSubmitting,errors])


  return (
    <div className='container'>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8 col-md-11 my-2">
          <div className="link-container" ref={provided.innerRef}  {...provided.draggableProps} style={{...provided.draggableProps.style,boxShadow:snapshot.isDragging?"0 0 .4rem #666":"none"}} >
            <div className="links-dots" {...provided.dragHandleProps}><span><MoreVertIcon fontSize="large" className='dot-icon' /></span></div>
            <div className="link-part">
              <div className="edit-linkTitle">
                <div className='edit-linkTitle-wrap'>
                  {(editTitle) ?
                    <form onSubmit={handleSubmit}>
                      <input type="search"
                        name='linkTitle'
                          value={values.linkTitle}
                           onChange={handleChange}
                        autoFocus="autofocus"
                            onBlur={handleSubmit}
                        placeholder={'Title'}
                        className={errors.linkTitle ? 'error-url-text' : 'edit-link-input'}
                      />
                    </form>
                    :
                    link.linkTitle
                  }
                  <div className=''>  {errors.linkTitle && (<small className="error-msg-link">{errors.linkTitle}</small>)} </div>
                </div>
                <span className='edit-button' onClick={toggleEditTitle}>
                {(editTitle)?'':<Button type="button" variant="no"><i class="far fa-edit"></i></Button>} 
                       </span>
              </div>
              <div className="edit-url">
              <div className="edit-url-wrap">
                {(editURL)?
                  <form onSubmit={handleSubmit} >
                            <input type="search" 
                              name='url'
                              value={values.url}
                              onChange={handleChange}
                              autoFocus="autofocus" 
                              onBlur={handleSubmit}
                              placeholder={'URL'}
                              className={errors.url ? 'error-url-text' : 'edit-link-input'}
                            />
                        </form>
                        : <div>{link.url}</div>
                }
                <div className=''>  {errors.url && (<small className="error-msg-link">{errors.url}</small>)} </div>
              </div>
              <span className='edit-button' onClick={toggleEditURL}>
                      {(editURL) ? '' : <Button type="button" variant="no"><i class="far fa-edit"></i></Button>}
                        </span>
              </div>
              <span className='delete-button'>
                 <Button type="button" variant="no" onClick={()=>deleteFunc(link._id)}><i class="fas fa-trash-alt"></i></Button>
                 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditableLink