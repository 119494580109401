import Snackbar from '@mui/material/Snackbar';
import React, { useState } from 'react'
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={5} ref={ref} variant="filled" {...props} />;
  });

  export  const snackbarContext=React.createContext()
  
  export default function CustomizedSnackbars({children}) {
    const [stateSnackbar,setStateSnackbar]=useState({
        open:false,
        severity:"",
        message:""
    })
    const {open,severity,message}=stateSnackbar
    const setStateSnackbarContext=(open,message,severity)=>setStateSnackbar({...stateSnackbar,open,message,severity})
    const handleClose=()=>setStateSnackbar({...stateSnackbar,open:false})
    const capitalizedMessage = message.toUpperCase();
    return (
        <snackbarContext.Provider value={setStateSnackbarContext}>
         <Snackbar anchorOrigin={{vertical:"top", horizontal:"center"}} open={open} autoHideDuration={5000} onClose={handleClose}>
           <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} >
            {capitalizedMessage}
           </Alert>
         </Snackbar>
         {children}
         </snackbarContext.Provider>
     );
  }