
import React, { useEffect, useState } from 'react'
import './reset.css'
import LockIcon from '@mui/icons-material/Lock';
import ErrorIcon from '@mui/icons-material/Error';
import inputErrors from '../helpers/validation'
import { useDispatch,useSelector } from 'react-redux';
import { useParams,useNavigate } from 'react-router-dom';
import { newPassword,clearError } from '../../redux/reducers/authSlice';
import {snackbarContext} from '../helpers/Snakbar'
import { TabTitle } from '../helpers/GeneralFunctions';
const ResetPassword = () => {
  TabTitle("Reset Password")
    const {error:backendError}=useSelector((state)=>state.auth)
    const setStateSnackbarContext=React.useContext(snackbarContext)
    const params=useParams()
    const navigate=useNavigate()
     const token=params.token
    const [values,setValues]=useState({
        password:"",
        confirmPassword:""
    })
    const dispatch=useDispatch()
    const [errors, setErrors] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);
    const handleSubmit=(e)=>{
        e.preventDefault()
        const validationErrors=inputErrors(values)
        setErrors(validationErrors)
        setSubmitting(true);
    }
    const handleChange=(event)=>{
        setValues({
            ...values,
            [event.target.name]: event.target.value
          });    
    }
    const newPass=()=>{
        setSubmitting(false)
         dispatch(newPassword({token,values,setStateSnackbarContext,navigate}))
    }
    useEffect(()=>{
        if(isSubmitting){
            const noErrors = Object.keys(errors).length === 0;   
            if(noErrors){
                newPass()
             }
        }
        if(backendError){
            setStateSnackbarContext(
              true,
              backendError,
              "error"
            )
            dispatch(clearError())
          }
    },[isSubmitting,errors,backendError])
  return (
    <div className="resetContainer">
    <div className="resetBox">
    <h2>Change Password</h2>
    <form className='resetPasswordForm'   onSubmit={handleSubmit} >
    <div className={errors.password?"input-box-error":"input-box"}>
      <input type="password"
      id='password'
       value={values.password}
      name='password'
      placeholder=' '
      className='input-field-login'
       onChange={handleChange}
       />
       <label htmlFor="password" className="input-label" >Password</label>
       <span className="label-icon"><LockIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>

    <div>  {errors.password &&  ( <small className="error-msg">{errors.password}</small>) }</div>

   <div className={errors.confirmPassword?"input-box-error":"input-box"}>
      <input type="password"
      id='confirmPassword'
       value={values.confirmPassword}
      name='confirmPassword'
      placeholder=' '
      className='input-field-login'
       onChange={handleChange}
       />
       <label htmlFor="confirmPassword" className="input-label" > Confirm Password</label>
       <span className="label-icon"><LockIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.confirmPassword &&  ( <small className="error-msg">{errors.confirmPassword}</small>) }</div>
    <input type="submit" value="Update" className='resetPasswordBtn'  />
    </form>
    </div>
    </div>
  )
}

export default ResetPassword